import React from "react";
import { Input, Row, Table } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";

const LetterForCounsellingDataTable = ({
  questionsList,
  setQuestionsList,
  setErrorMessage,
  errorMessage,
}) => {
  const handleRating = (e, index, val) => {
    const { value } = e.target;
    let tempValues = [...questionsList];
    tempValues[index].rating = parseInt(value);
    tempValues[index].errorColor = "#ffffff";
    if (tempValues.every((question) => question.rating !== 0)) {
      setErrorMessage({
        ...errorMessage,
        questions: "",
      });
    }
    setQuestionsList(tempValues);
  };
  return (
    <>
      <Table responsive bordered>
        <thead>
          <tr>
            <th style={{ fontSize: 15 }} className="text-center">
              <GetLanguageString
                props={"settings_letter_for_counselling_question_q_number"}
              />
            </th>
            <th style={{ fontSize: 15 }}>
              <GetLanguageString
                props={"settings_letter_for_counselling_questions"}
              />
            </th>
            <th style={{ fontSize: 15 }} className="ur text-center">
              تسلی بخس
            </th>
            <th style={{ fontSize: 15 }} className="ur text-center">
              بہتر ہوئی ہے{" "}
            </th>
            <th style={{ fontSize: 15 }} className="ur text-center">
              کوشش جاری ہے
            </th>
            <th style={{ fontSize: 15 }} className="ur text-center">
              ارادہ ہے
            </th>
          </tr>
        </thead>
        <tbody>
          {questionsList.map((item, index) => {
            return (
              <tr key={index} style={{ backgroundColor: item.errorColor }}>
                <td style={{ fontSize: 15 }} className="text-center">
                  {item.questionNumber}
                </td>
                <td style={{ fontSize: 17 }} className="ur">
                  {item.questionTitle}
                </td>
                <td
                  className="text-center"
                  onClick={() => handleRating({ target: { value: 1 } }, index)}
                  style={{ cursor: "pointer" }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Input
                      id={"question1-" + item.questionId}
                      type="radio"
                      name={"question1-" + item.questionId}
                      checked={item.rating === 1}
                      value={1}
                      onChange={(e) => {
                        handleRating(e, index);
                      }}
                      style={{
                        pointerEvents: "none",
                        transform: "scale(1.2)",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                </td>
                <td
                  className="text-center"
                  onClick={() => handleRating({ target: { value: 2 } }, index)}
                  style={{ cursor: "pointer" }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Input
                      id={"question2--" + item.questionId}
                      type="radio"
                      name={"question2-" + item.questionId}
                      checked={item.rating === 2}
                      value={2}
                      onChange={(e) => handleRating(e, index)}
                      style={{
                        pointerEvents: "none",
                        transform: "scale(1.2)",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                </td>
                <td
                  className="text-center"
                  onClick={() => handleRating({ target: { value: 3 } }, index)}
                  style={{ cursor: "pointer" }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Input
                      id={"question3-" + item.questionId}
                      type="radio"
                      name={"question3-" + item.questionId}
                      checked={item.rating === 3}
                      value={3}
                      onChange={(e) => handleRating(e, index)}
                      style={{
                        pointerEvents: "none",
                        transform: "scale(1.2)",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                </td>
                <td
                  className="text-center"
                  onClick={() => handleRating({ target: { value: 4 } }, index)}
                  style={{ cursor: "pointer" }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Input
                      id={"question4-" + item.questionId}
                      type="radio"
                      name={"question4-" + item.questionId}
                      checked={item.rating === 4}
                      value={4}
                      onChange={(e) => handleRating(e, index)}
                      style={{
                        pointerEvents: "none",
                        transform: "scale(1.2)",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {errorMessage.frequency && (
        <Row>
          <h5 className="text-error">
            * {<GetLanguageString props={errorMessage.frequency} />}
          </h5>
        </Row>
      )}
      {errorMessage.selectedFrequency && (
        <Row>
          <h5 className="text-error">* {errorMessage.selectedFrequency}</h5>
        </Row>
      )}
      {errorMessage.questions && (
        <Row>
          <h5 className="text-error">
            * {<GetLanguageString props={errorMessage.questions} />}
          </h5>
        </Row>
      )}
    </>
  );
};

export default LetterForCounsellingDataTable;
