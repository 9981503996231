import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { GetLanguageString } from "../../helper/Components";
import { ApiResponsibility } from "../../config/responsibilityType/ApiResponsibilityType";
import { GetSelectedLanguage } from "../../helper/Method";
import { convertToDateString, isUrdu } from "../../../constants/const";

const SelectResponsibilityData = ({
  values,
  setValues,
  errors,
  setErrors,
  selectedUnit,
  memberId,
  editResponsibilityDate = null,
  type,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    values.responsibility !== null
      ? values.responsibility
      : {
          value: 0,
          label: "",
          isMultiple: false,
        }
  );
  const [responsibilityDate, setResponsibilityDate] = useState(
    editResponsibilityDate
  );
  const [responsibility, setResponsibility] = useState([]);
  const responsibilityOptions = [];
  responsibility.forEach((r) =>
    responsibilityOptions.push({
      label: r.type,
      value: r.id,
      isMultiple: r.isMultiple,
    })
  );
  const [displayName, setDisplayName] = useState(values.displayName);

  useEffect(() => {
    ApiResponsibility()
      .getUnitResponsibilitiesByPermissions(selectedUnit, memberId)
      .then((res) => {
        setResponsibility(res.data);
        if (type === "add") {
          setSelectedOption({});
          setValues({
            ...values,
            responsibility: {},
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit, memberId]);

  useEffect(() => {
    if (values.responsibility.value !== 0 || responsibility.length !== 0) {
      const filteredResponsibility = responsibility.filter(
        (item) => item.id === values.responsibility.value
      );

      const selectedResponsibility =
        filteredResponsibility.length > 0
          ? filteredResponsibility[0]
          : responsibility.length > 0
          ? responsibility[0]
          : null;

      if (selectedResponsibility) {
        setSelectedOption({
          label: selectedResponsibility.type,
          value: selectedResponsibility.id,
          isMultiple: selectedResponsibility.isMultiple,
        });

        setValues({
          ...values,
          responsibility: {
            label: selectedResponsibility.type,
            value: selectedResponsibility.id,
            isMultiple: selectedResponsibility.isMultiple,
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsibility]);

  useEffect(() => {
    setValues({
      ...values,
      responsibility: selectedOption,
    });
    // eslint-disable-next-line
  }, [selectedOption]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    if (name === "displayName") {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleChangeResponsibility = (record) => {
    if (record !== null) {
      setValues({
        ...values,
        responsibility: record,
      });
      setSelectedOption(record);
      setErrors({
        ...errors,
        responsibility: "",
      });
    }
  };

  const handleDisplayNameChange = (e) => {
    const { value } = e.target;
    setDisplayName(value);
    setErrors({
      ...errors,
      displayName: "",
    });
  };

  useEffect(() => {
    values.displayName = displayName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName]);

  const handleInputChangeResponsibilityDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setResponsibilityDate(value);
        setValues({
          ...values,
          responsibilityDate: convertToDateString(value),
        });
        setErrors({
          ...errors,
          responsibilityDate: "",
        });
      } else {
        setErrors({
          ...errors,
          responsibilityDate:
            GetSelectedLanguage() === "en"
              ? "The End date must not be greater than current date."
              : "ذمہ داری ک ختم ہونے ک تاریخ موجودہ تاریخ سی بری نہیں ہو سکتی",
        });
      }
    } else {
      setResponsibilityDate(null);
    }
  };
  return (
    <>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_select_responsibilities" />}
            </Label>
            <Select
              value={
                values.responsibility.value === 0 &&
                responsibilityOptions.length === 0
                  ? null
                  : selectedOption
              }
              className={
                values.responsibility.value === 0 &&
                responsibilityOptions.length === 0
                  ? "basic-single no-drag"
                  : "basic-select ur no-drag"
              }
              placeholder={
                values.responsibility.value === 0 &&
                responsibilityOptions.length === 0 ? (
                  <GetLanguageString props="member_no_responsibility_available" />
                ) : GetSelectedLanguage() === "en" ? (
                  "Select"
                ) : (
                  "منتخب کریں"
                )
              }
              options={responsibilityOptions}
              onChange={handleChangeResponsibility}
              isDisabled={values.responsibilityPermissionExists}
            />
            {errors.responsibility && (
              <h4 className="text-error">{errors.responsibility}</h4>
            )}
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label">
              {
                <GetLanguageString props="member_member_detail_common_responsibility_date" />
              }
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className="form-control no-drag"
              dateFormat="dd/MM/yyyy"
              disabled={values.responsibilityPermissionExists}
              selected={responsibilityDate}
              maxDate={new Date()}
              onChange={handleInputChangeResponsibilityDate}
            />
            {errors.responsibilityDate && (
              <h4 className="text-error">{errors.responsibilityDate}</h4>
            )}
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_reference_note" />}
            </Label>
            <Input
              type="text"
              name="referenceNote"
              className="no-drag"
              value={values.referenceNote}
              disabled={values.responsibilityPermissionExists}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      {selectedOption.isMultiple && (
        <Row>
          <Col md="4">
            <FormGroup>
              <Label className="col-form-label">
                {<GetLanguageString props="responsibility_title" />}
              </Label>
              <Input
                type="text"
                name="displayName"
                className={isUrdu(displayName) ? "no-drag ur" : "no-drag"}
                value={displayName}
                onChange={handleDisplayNameChange}
                {...(errors?.displayName && { invalid: true })}
              />
              {errors.displayName && (
                <h4 className="text-error">{errors.displayName}</h4>
              )}
            </FormGroup>
          </Col>
        </Row>
      )}
      <div class="divider div-transparent div-dot"></div>
    </>
  );
};

export default SelectResponsibilityData;
