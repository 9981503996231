import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";

import { ApiUnit } from "./ApiUnit";
import { encryptStorage } from "../../constants/EncryptStorage";
import { LoadingSpinner } from "../../constants/const";
import { GetSelectedLanguage } from "../helper/Method";
import { GetLanguageString } from '../helper/Components';

const SelectUnitForMoveUnit = ({
  setValues,
  values,
  setErrors,
  unitId,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const unitType = "null";
  if (unitId === 0) unitId = loggedInUnitId;

  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, false, unitType, excludeExUnit)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId, unitType, excludeExUnit]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitType, excludeExUnit)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setValues({
        ...values,
        unit: record,
      });
      setErrors({
        unitId: "",
      });
    }
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <>
      {unitList.length !== 0 ? (
        <Row>
          {unitList.map((record, index) => {
            const unitOptions = [];
            record.unitList.forEach((d) =>
              d.unitTypeName !== "UsraMuavin" &&
              d.unitTypeName !== "Munfarid" &&
              d.unitTypeName !== "EX"
                ? unitOptions.push({
                    value: d.id,
                    label: d.name,
                    unitName: d.unitTypeName,
                  })
                : null
            );
            let selected = {};
            if (record.selectedUnit !== null) {
              selected = {
                value: record.selectedUnit.id,
                label: record.selectedUnit.name,
              };
            }

            const isLastIndex = index === unitList.length - 1;

            return (
              <Col md="3" key={index}>
                <FormGroup>
                  <Row>
                    <Col md="10">
                      <Select
                        options={unitOptions}
                        value={selected}
                        className="basic-single ur"
                        classNamePrefix="select"
                        onChange={handleChangeUnits}
                      />
                    </Col>
                    {isLastIndex ? null : (
                      <Col md="2" className="p-0">
                        {GetSelectedLanguage() === "en" ? (
                          <i className="fa fa-arrow-right fa-lg m"></i>
                        ) : (
                          <i className="fa fa-arrow-left fa-lg m"></i>
                        )}
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup check>
                        <Label check for="excludeExUnit">
                          <Input
                            id="excludeExUnit"
                            type="checkbox"
                            name="excludeExUnit"
                            checked={!excludeExUnit}
                            value={!excludeExUnit}
                            onChange={handleExcludeExUnit}
                          />
                          {<GetLanguageString props="member_search_member_ex_unit" />}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            );
          })}
        </Row>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default SelectUnitForMoveUnit;
