import React from "react";
import { Col, Row } from "reactstrap";
import { GetCustomStyles } from "../../helper/Method";
import { GetLanguageString } from "../../helper/Components";

const LetterForCounsellingMemberDataContainer = ({ memberValues }) => {
  return (
    <div>
      {" "}
      <Row>
        <Col md="6">
          <Row>
            <Col md="4">
              <h4 className={GetCustomStyles().btn_style}>
                {<GetLanguageString props="common_name" />} :
              </h4>
            </Col>

            <h3 className="col-7 ur">{memberValues.memberName}</h3>
          </Row>
          <Row>
            <Col md="4">
              {" "}
              <h4 className={GetCustomStyles().btn_style}>
                {<GetLanguageString props="member_responsibility" />} :
              </h4>
            </Col>

            <h3 className="col-7 ur">
              {memberValues.memberResponsibility !== "،"
                ? memberValues.memberResponsibility
                : "-"}
            </h3>
          </Row>
        </Col>
        <Col md="6">
          <Row>
            <Col md="4">
              <h4 className={GetCustomStyles().btn_style}>
                {<GetLanguageString props="member_reg_number" />} :
              </h4>
            </Col>
            <h3 className="col-7">{memberValues.memberRegNo}</h3>
          </Row>
          <Row>
            <Col md="4">
              {" "}
              <h4 className={GetCustomStyles().btn_style}>
                {<GetLanguageString props="unit" />} :
              </h4>
            </Col>

            <h3 className="col-7 ur">
              {memberValues?.memberUnit?.replace(/،/g, " / ")}
            </h3>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LetterForCounsellingMemberDataContainer;
