import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Row, Table } from "reactstrap";
import moment from "moment";
import { saveAs } from "file-saver";

import {
  GetLanguageString,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import { GetCustomStyles } from "../../helper/Method";
import { LoadingSpinner } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import SearchMemberStatusChange from "./SearchMemberStatusChange";
import { addMember } from "../../member/ApiMember";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";

const MemberStatusChangeReport = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [multazimMembers, setMultazimMembers] = useState([]);
  const [mutazirMembers, setMutazirMembers] = useState([]);
  const [tawajahTalabMembers, setTawajahTalabMembers] = useState([]);
  const [searchButton, setSearchButton] = useState(false);

  let tempDate = new Date();
  tempDate.setUTCDate(1);
  const [values, setValues] = useState({
    unit: { value: loggedInUnitId, lable: "" },
    isSubUnits: true,
    startDate: tempDate,
    endDate: new Date(),
  });

  useEffect(() => {
    if (searchButton) {
      setLoading(true);
      addMember()
        .getMemberStatusChangeReport(values)
        .then((res) => {
          setMultazimMembers(res.data.multazimMemberList);
          setMutazirMembers(res.data.mutazirMemberList);
          setTawajahTalabMembers(res.data.tawajahTalabMemberList);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [values, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const excelDownload = () => {
    const reportData = {
      multazimMemberList: multazimMembers,
      mutazirMemberList: mutazirMembers,
      tawajahTalabMemberList: tawajahTalabMembers,
    };

    addMember()
      .generateMemberStatusChangeReport(reportData)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "MemberStatusChangeReport.xlsx");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
          </div>
          <SearchMemberStatusChange
            memberStatusChangeValues={values}
            setMemberStatusChangeValues={setValues}
            setSearchButton={setSearchButton}
          />
        </div>
        {searchButton ? (
          <div className="ibox">
            <div className="ibox-title">
              <h3>
                <GetLanguageString
                  props="quick_reports4_title"
                  value={moment(values.startDate).format("DD/MM/yyyy")}
                />{" "}
                {moment(values.endDate).format("DD/MM/yyyy")})
              </h3>
              <ButtonGroup className={GetCustomStyles().err_btn_group_style}>
                <Button color="default" size="sm" onClick={excelDownload}>
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="member_report_export_excel" />}
                </Button>
                <Button color="default" size="sm" onClick={handleReportTitle}>
                  <FcPrint size={15} className={GetCustomStyles().fa_style} />
                  {<GetLanguageString props="common_print" />}
                </Button>
              </ButtonGroup>
            </div>
            <div className="ibox-content ibox-center" id="section-to-print">
              <Row className="print">
                <div className="text-center">
                  <h3>Title : {reportTitle}</h3>
                </div>
              </Row>
              {!loading ? (
                <>
                  <MemberList
                    name="quick_reports4_rufqa_multazim"
                    list={multazimMembers}
                  />
                  <MemberList
                    name="quick_reports4_rufqa_motazir"
                    list={mutazirMembers}
                  />
                  <MemberList
                    name="quick_reports4_rufqa_tawajah_talab"
                    list={tawajahTalabMembers}
                  />
                </>
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MemberStatusChangeReport;

const MemberList = ({ name, list }) => {
  return (
    <div className="panel panel-success">
      <div className="panel-heading">
        <h3 className="text-center">
          <GetLanguageString props={name} />
        </h3>
      </div>
      {list.length !== 0 ? (
        <div className="table-responsive">
          <Table className="table-bordered m-b-none">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <GetLanguageString props="common_name" />
                </th>
                <th>
                  <GetLanguageString props="member_father_name" />
                </th>
                <th>
                  <GetLanguageString props="quick_reports3_unit_name" />
                </th>
                <th>{<GetLanguageString props="member_address" />}</th>
                <th>{<GetLanguageString props="member_phone" />}</th>
                <th>
                  <GetLanguageString props="quick_reports4_declaration_date" />
                </th>
                <th>
                  <GetLanguageString props="quick_reports4_reference_note" />
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((record, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="ur">{record.fullName}</td>
                    <td className="ur">{record.fatherName}</td>
                    <td className="ur">{record.unitName}</td>
                    <td className="ur">{record.currentAddress}</td>
                    <td>
                      {record.phone != null
                        ? record.phone
                            .split(",")
                            .map((phoneNumber, index) => (
                              <div key={index}>{phoneNumber}</div>
                            ))
                        : ""}
                    </td>
                    <td>
                      {moment(record.declarationDate).format("DD/MM/yyyy")}
                    </td>
                    <td>{record.referenceNote}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <NoResult />
      )}
    </div>
  );
};
