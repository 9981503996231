import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import swal from "sweetalert";
import { convertToDateString, LoadingSpinner } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import {
  GetCustomStyles,
  GetLetterForCounselingFrequencyList,
  GetSelectedLanguage,
} from "../../helper/Method";
import { addMember } from "../ApiMember";
import LetterForCounsellingDataTable from "./LetterForCounsellingDataTable";
import LetterForCounsellingMemberDataContainer from "./LetterForCounsellingMemberDataContainer";

const AddLetterForCounselling = ({ history }) => {
  const location = useLocation();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const memberId = location.state?.memberId;
  const letterForCounselling = location.state?.letterForCounselling;
  const memberValues = location.state?.memberValues;
  const letterFrequency = location.state?.letterFrequency;
  const frequency =
    letterFrequency === 12
      ? currentMonth
      : letterFrequency === 6
      ? currentMonth <= 6
        ? 1
        : 2
      : letterFrequency === 4
      ? Math.ceil(currentMonth / 3)
      : letterFrequency === 2
      ? currentMonth <= 6
        ? 1
        : 2
      : 0;

  const { t } = useTranslation();
  let frequencyList = GetLetterForCounselingFrequencyList(letterFrequency);
  const [filteredFrequencyList, setFilteredFrequencyList] = useState();
  const [errorMessage, setErrorMessage] = useState({
    questions: "",
    selectedFrequency: "",
    frequency: "",
  });
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [selectedYear, setSelectedYear] = useState(new Date(currentYear, 0, 1));
  const [alreadyAddedLfcFrequencies, setAlreadyAddedLfcFrequencies] = useState(
    []
  );
  const [selectedFrequency, setSelectedFrequency] = useState();
  const [loading, setLoading] = useState(false);

  const [questionsList, setQuestionsList] = useState([]);

  const filterData = (year) => {
    const filteredFrequencyData = alreadyAddedLfcFrequencies.filter(
      (item) => item.year === year.getFullYear()
    );
    const frequencies = filteredFrequencyData.map((item) => item.frequency);
    const filteredMonths = frequencyList.filter(
      (month) => !frequencies.includes(month.value)
    );

    if (filteredMonths.length === 0) {
      setErrorMessage({
        ...errorMessage,
        frequency:
          "member_member_detail_letter_for_counselling_already_exist_error_message",
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        frequency: "",
      });
    }
    setFilteredFrequencyList(filteredMonths);
    let filteredFrequency = filteredMonths?.find(
      (item) => item.value === frequency
    );
    setSelectedFrequency(
      filteredFrequency !== undefined ? filteredFrequency : filteredMonths[0]
    );
  };

  useEffect(() => {
    setLoading(true);
    addMember()
      .GetLetterForCounsellingQuestions(letterForCounselling.value, memberId)
      .then((res) => {
        let tempValues = [...res.data.memberLetterForCounsellingQuestions];

        tempValues = tempValues.map((question) => {
          return { ...question, errorColor: "#ffffff" };
        });

        setQuestionsList(tempValues);
        setAlreadyAddedLfcFrequencies(res.data.alreadyAddedLfcFrequencies);
        setLoading(false);
      })
      .catch((err) => {
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
      });
  }, [letterForCounselling, memberId]);
  // console.log(questionsList);

  useEffect(() => {
    if (alreadyAddedLfcFrequencies.length !== 0) {
      filterData(currentDate);
    }
    // eslint-disable-next-line
  }, [alreadyAddedLfcFrequencies]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      questionsList.every((question) => question.rating !== 0) &&
      Object.values(errorMessage).every((x) => x === "") &&
      selectedFrequency !== undefined &&
      Object.keys(selectedFrequency).length !== 0
    ) {
      const values = {
        memberId: memberId,
        letterForCounsellingId: letterForCounselling.value,
        letterFrequency: selectedFrequency.value,
        receivedAt: convertToDateString(selectedDate),
        letterYear: selectedYear.getFullYear(),
        questions: questionsList,
      };
      setLoading(true);
      addMember()
        .AddMemberLetterForCounselling(values)
        .then((res) => {
          swal({
            title: t(
              "member_member_detail_letter_for_counselling_added_success"
            ),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          history.goBack();
          setLoading(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    } else {
      if (questionsList.some((question) => question.rating === 0)) {
        let tempValues = [...questionsList];

        tempValues = tempValues.map((question) => {
          if (question.rating === 0) {
            return { ...question, errorColor: "#FFEBEB" };
          }
          return question;
        });

        setQuestionsList(tempValues);
      }
      setErrorMessage({
        ...errorMessage,
        questions: questionsList.some((question) => question.rating === 0)
          ? "member_member_detail_letter_for_counselling_questions_error_message"
          : "",
        selectedFrequency:
          selectedFrequency === undefined ||
          Object.keys(selectedFrequency).length === 0
            ? GetSelectedLanguage() === "ur"
              ? "فریکوئنسی منتخب کریں "
              : "Please Select Frequency"
            : "",
        frequency:
          filteredFrequencyList.length === 0
            ? "member_member_detail_letter_for_counselling_already_exist_error_message"
            : "",
      });
    }
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="panel panel-primary">
        <div className="panel-heading">
          {" "}
          <Row>
            <Col md="10"></Col>
            <Col md="2">
              <Button
                color="info"
                size="sm"
                className={GetCustomStyles().btn_style}
                onClick={() => history.goBack()}
              >
                {<GetLanguageString props="unit_back_btn" />}
              </Button>
            </Col>
          </Row>
        </div>
        <div className="panel-body">
          <LetterForCounsellingMemberDataContainer
            memberValues={memberValues}
          />
          <br></br>
          <br></br>
          <Row>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="settings_letter_for_counselling" />
                  }
                </Label>
                <Input
                  className={"ur"}
                  type="text"
                  disabled
                  value={letterForCounselling.label}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup style={{ marginTop: 5 }}>
                <Label>
                  {<GetLanguageString props="settings_event_type_frequency" />}
                </Label>
                <Select
                  value={selectedFrequency}
                  options={filteredFrequencyList}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setSelectedFrequency(e);
                    setErrorMessage({
                      ...errorMessage,
                      frequency: "",
                      selectedFrequency: "",
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="member_member_detail_letter_for_counselling_received_date" />
                  }
                </Label>
                <DatePicker
                  showYearDropdown
                  placeholderText="dd/mm/yyyy"
                  className="form-control"
                  selected={selectedDate}
                  maxDate={currentDate}
                  onChange={(d) => setSelectedDate(d)}
                  dateFormat="dd/MM/yyyy"
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="member_member_detail_letter_for_counselling_year" />
                  }
                </Label>
                <DatePicker
                  showYearPicker
                  dateFormat="yyyy"
                  className="form-control"
                  selected={selectedYear}
                  maxDate={currentDate}
                  onChange={(y) => {
                    setSelectedYear(y);
                    filterData(y);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>{" "}
          {errorMessage.frequency && (
            <Row>
              <h5 className="text-error">
                * {<GetLanguageString props={errorMessage.frequency} />}
              </h5>
            </Row>
          )}
          {errorMessage.selectedFrequency && (
            <Row>
              <h5 className="text-error">* {errorMessage.selectedFrequency}</h5>
            </Row>
          )}
          {errorMessage.questions && (
            <Row>
              <h5 className="text-error">
                * {<GetLanguageString props={errorMessage.questions} />}
              </h5>
            </Row>
          )}
        </div>
      </div>
      <div className="ibox-content">
        {!loading ? (
          <>
            <LetterForCounsellingDataTable
              questionsList={questionsList}
              setQuestionsList={setQuestionsList}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
            />
            <Row>
              <div className={GetCustomStyles().btn_style}>
                {" "}
                <Button
                  color="primary"
                  size="sm"
                  className={GetCustomStyles().btn_style}
                  onClick={handleSubmit}
                >
                  <GetLanguageString props="Submit" />
                </Button>
              </div>{" "}
            </Row>
          </>
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default withRouter(AddLetterForCounselling);
